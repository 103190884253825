import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private http: HttpClientService) {}

  public getAllNotificationsPaginate(page?: number) {
    return this.http.get("user/notification/get-all", { page });
  }

  public getNotification(notificationId: string) {
    return this.http.get("user/notification/get", { id: notificationId });
  }

  public get markAsRead() {
    return this.http.get("user/notification/mark-as-read");
  }

  public get totalUnread() {
    return this.http.get("user/notification/total-unread-notifications");
  }
}
