import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClientService) {}

  public getLatestAction(body: { company_id: number; sort_by: string }) {
    return this.http.get("reports/last-actions-reports", body);
  }
  public getLatestActionByDates(dates, body: { company_id: number; sort_by: string }) {
    body['from'] = dates['from'];
    body['to'] = dates['to'];
    return this.http.get("reports/last-actions-reports", body);
  }

  public getNumbersOfLead(
    sortBy: string,
    userId?: number,
    isPaginate: boolean = false
  ) {
    return this.http.get("reports/number-of-leads-in-each-source", {
      sort_by: sortBy,
      user_id: userId,
      is_paginate: `${+isPaginate}`,
    });
  }
  public getNumbersOfLeadByDates(
    dates,
    sortBy: string,
    userId?: number,
    isPaginate: boolean = false,
  ) {
    return this.http.get("reports/number-of-leads-in-each-source", {
      sort_by: sortBy,
      user_id: userId,
      is_paginate: `${+isPaginate}`,
      from:dates.from,
      to: dates.to
    });
  }

  public getUsersLeadNum() {
    return this.http.get("reports/statistics");
  }
  public getUsersLeadNumByDates(dates) {
    return this.http.get("reports/statistics", dates);
  }

  public FilterAgentChart(userId?: number) {    
    return this.http.get("reports/agent-actions-status", { user_id: userId });
  }
  public FilterAgentChartByDates(dates, userId?: number) {
    return this.http.get("reports/agent-actions-status", { user_id: userId, from: dates.from, to: dates.to });
  }

}
