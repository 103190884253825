import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class UnitsService {
  constructor(private http: HttpClientService) {}

  public createUnit(body: {
    name_en: string;
    name_ar: string;
    min_price: number;
    max_price: number;
    year_payment: Date;
    image: string;
    unit_type_id: number;
    project_id: number;
    completion_type: string;
    top_unit: number;
    on_sale: number;
  }) {
    return this.http.postFormData(body, "unit/create");
  }

  public getAllUnits(
    isPaginate: boolean = false,
    page?: number,
    search?: string
  ) {
    if (!isPaginate) {
      page = null;
    }
    return this.http.get("unit/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
    });
  }

  public getUnit(unitId: number, isLocalization: boolean = false) {
    return this.http.get("unit/get", {
      unit_id: unitId,
      is_localization: `${+isLocalization}`,
    });
  }

  public updateUnit(body: {
    name_en: string;
    name_ar: string;
    min_price: number;
    max_price: number;
    year_payment: Date;
    image: string;
    unit_type_id: number;
    project_id: number;
    completion_type: string;
    top_unit: number;
    on_sale: number;
    unit_id: number;
  }) {
    return this.http.postFormData(body, "unit/update");
  }

  public deleteUnit(unitId: number) {
    return this.http.post({ unit_id: unitId }, "unit/delete");
  }
}
