import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessagingService } from "../notification/notification.service";
import { Subject } from "rxjs";

export interface IUser {
  company: {
    id: number;
    email: string;
  };
  agent: {id: number, company_id: number, user_id: number, title_id: number, counter: number}
  email: string;
  id: number;
  pages: [{ id: number; name: string; value: boolean }];
  role: {
    company_id: number;
    id: number;
    name: string;
    valid_permissions: string[];
  };
  role_id: number;
  token: string;
  username: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // store the URL so we can redirect after logging in
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(
    private http: HttpClientService,
    private router: Router,
    private messagingService: MessagingService
  ) {
    this.expiredTokenSubject = new Subject();
  }

  public login(body: {
    email: string;
    password: string;
    firebase_token: string;
  }) {
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    return this.http.post(body, "user/login");
  }

  public set User(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public get User(): IUser {
    return JSON.parse(localStorage.getItem("user"));
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public get getAdmin() {
    if (this.User && this.User.role_id === 1) {
      return "admin";
    } else {
      return "company";
    }
  }

  public isAdmin() {
    if (this.User && this.User.role_id === 1) {
      return true;
    }

    return false;
  }

  public isCompany() {
    if (this.User && this.User.role_id !== 1) {
      if (this.User.company) {
        return this.User.company.id;
      }
    }

    return null;
  }

  public logout() {
    const body: { firebase_token?: string } = {};
    if (this.messagingService.firebaseToken) {
      body.firebase_token = this.messagingService.firebaseToken;
    }
    this.http.post(body, "user/logout").subscribe(
      (_) => this.removeUser(),
      (_) => this.removeUser()
    );
  }

  removeUser() {
    localStorage.removeItem("user");
    this.router.navigate(["/auth/signin"]);
  }

  public editProfile(body) {
    return this.http.postFormData(body, "user/edit-profile");
  }
  public changePassword(body: {
    current_password: number;
    new_password: number;
    new_password_confirmation: number;
  }) {
    return this.http.postFormData(body, "user/change-password");
  }
  public resetPassword(body: {
    email: string;
    token: string;
    password: number;
    password_confirmation: any;
  }) {
    return this.http.postFormData(body, "user/reset-password");
  }
  public forgetPassword(body: { email }) {
    return this.http.post(body, "user/forget-password");
  }
  public getProfile(userId: number) {
    return this.http.get("user/get-profile", { user_id: userId });
  }
  public setEmailConfig(body) {
    return this.http.post(body , "company/set-email-config");
  }
  public getEmailConfid() {
    return this.http.get("company/get-email-config" )
  }
}
