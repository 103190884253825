import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

interface ILead {
  name: string;
  company_id?: number;
  title: string;
  phones: [
    {
      number: number;
      country_code: number;
    }
  ];
  sources: number[];
  email?: string;
  notes?: string;
}

interface IUpdateLead {
  lead_id: number;
  name: string;
  company_id?: number;
  title: string;
  phones: [
    {
      number: number;
      country_code: number;
    }
  ];
  sources: number[];
  email?: string;
  notes?: string;
}

@Injectable({
  providedIn: "root",
})
export class LeadsService {
  constructor(private http: HttpClientService) {}

  public creatLead(body: ILead) {
    return this.http.post(body, "lead/create");
  }

  public getLead(leadId: number) {
    return this.http.get("lead/get", { lead_id: leadId });
  }

  public getFacebookPages(page: number = 1) {
    return this.http.get("lead/facebook/get-facebook-pages", { page });
  }

  getfbPagesFromDataBase(isPaginate: boolean = false, page: number = 1) {
    return this.http.get("lead/facebook/get-fb-pages", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
  public addFacebookLeads(body) {
    return this.http.post(body, "lead/facebook/store-page-data");
  }
  public getPageForms(
    pageId: number,
    isPaginate: boolean = false,
    page: number = 1
  ) {
    return this.http.get("lead/facebook/get-page-forms", {
      page_id: pageId,
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
  public deleteUserFromForm(formId: number, userId: number) {
    return this.http.post(
      { form_id: formId, user_id: userId },
      "lead/facebook/delete-from-form"
    );
  }

  public getAllLeads(
    isPaginate: boolean = false,
    page: number = 1,
    search?: string,
    is_admin: boolean = true
  ) {
    return this.http.get("lead/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
      search,
      is_admin,
    });
  }

  public updateLead(body: IUpdateLead) {
    return this.http.post(body, "lead/update");
  }

  public assignLeadsToUsers(body: {
    lead_id: number;
    users: number[];
    company_id: number;
  }) {
    return this.http.post(body, "lead/assign-lead-to-users");
  }
  public uploadfile(file: File) {
    return this.http.postFormData(file, "lead/import-bulk");
  }

  public deleteLead(leadId: number) {
    return this.http.post({ lead_id: leadId }, "lead/delete");
  }

  public addfromcontact(body: {
    title: string;
    name: string;
    notes: string;
    phones: number;
    number: number;
    country_code: number;
    email: string;
    company_id: number;
  }) {
    return this.http.post(body, "lead/add-from-contacts");
  }

  public storeLeadsFromForm(formId: number) {
    return this.http.get("lead/facebook/store-leads-from-form", {
      form_id: formId,
    });
  }

  public addAgentToForm(body: {
    form_id: number;
    user_id: number;
    percentage: string;
  }) {
    return this.http.post(body, "lead/facebook/add-to-forms");
  }
  public filterLeads(body) {
    return this.http.get("reports/leads-by-action-type", body);
  }
  public filterbydate(body) {
    return this.http.get("lead/filter-by-date", body);
  }

  public sendReceipt(body) {
    return this.http.post(body, "receipt/create");
  }
}
