<perfect-scrollbar
  *ngIf="this.nextConfig['layout'] === 'vertical'"
  (clickOutside)="navMob()"
  [excludeBeforeClick]="true"
  [exclude]="'#mobile-collapse'"
  id="nav-ps-next"
  ngClass="able-pro-scroll"
  [style.max-width]="'300px'"
  [style.max-height]="'calc(100vh - 60px)'"
  [usePSClass]="'next'"
  [disabled]="null"
>
  <div class="navbar-content">
    <div class="">
      <div class="main-menu-header">
        <!-- <img
          class="img-radius"
          src="../../../../../../assets/images/profile/photo-1419064642531-e575728395f2.jpg"
          alt="User-Profile-Image"
        /> -->
        <img
          class="img-radius"
          src="../../../../../../assets/images/dn-new-black.png"
          alt="User-Profile-Image"
        />
        <div class="user-details" (click)="isNavProfile = !isNavProfile">
          <div id="more-details">
            {{ user.username }}
            <i
              class="fa fa-caret-down"
              [ngClass]="{ 'fa-caret-up': isNavProfile }"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="collapse mt-3"
        id="nav-user-link"
        *ngIf="isNavProfile"
        [style.display]="'block'"
      >
        <ul class="list-unstyled">
          <li class="list-group-item">
            <a href="javascript:" [routerLink]="['/profile']"
              ><i class="feather icon-user m-r-5"></i>View Profile</a
            >
          </li>
          <!-- <li class="list-group-item">
            <a href="javascript:"
              ><i class="feather icon-settings m-r-5"></i>Settings</a
            >
          </li> -->
          <li class="list-group-item">
            <a href="javascript:" (click)="logout()"
              ><i class="feather icon-log-out m-r-5"></i>Logout</a
            >
          </li>
        </ul>
      </div>
    </div>
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <!-- <app-card [hidHeader]="true" cardClass="text-center">
      <i class="feather icon-sunset f-40"></i>
      <h6 class="mt-3">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a
        href="javascript:"
        target="_blank"
        class="btn btn-primary btn-sm text-white m-0"
        >Support</a
      >
    </app-card> -->
  </div>
</perfect-scrollbar>

<div
  *ngIf="this.nextConfig['layout'] === 'horizontal'"
  #navbarWrapper
  class="navbar-content sidenav-horizontal"
  id="layout-sidenav"
>
  <a
    href="javascript:"
    class="sidenav-horizontal-prev"
    [ngClass]="prevDisabled"
    (click)="scrollMinus()"
  ></a>
  <div class="sidenav-horizontal-wrapper">
    <!-- add 14-4 viral -->
    <ul
      #navbarContent
      id="side-nav-horizontal"
      class="nav pcoded-inner-navbar sidenav-inner"
      (clickOutside)="fireLeave()"
      (mouseleave)="fireLeave()"
    >
      <ng-container *ngFor="let item of navigation">
        <app-nav-group
          *ngIf="item.type == 'group'"
          [item]="item"
        ></app-nav-group>
        <app-nav-collapse
          *ngIf="item.type == 'collapse'"
          [item]="item"
        ></app-nav-collapse>
        <app-nav-item *ngIf="item.type == 'item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a
    href="javascript:"
    class="sidenav-horizontal-next"
    [ngClass]="nextDisabled"
    (click)="scrollPlus()"
  ></a>
</div>
