import { Injectable } from "@angular/core";
import { NgModel } from "@angular/forms";
import { HttpClientService } from "src/app/http-confing/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  constructor(private http: HttpClientService) {}

  public createProduct(body) {
    return this.http.postFormData(body, "product/create");
  }
  public updateProduct(body) {
    return this.http.postFormData(body, "product/update");
  }
  public deleteProduct(productId: number) {
    return this.http.post({ product_id: productId }, "product/delete");
  }
  public getProductData(productId: number) {
    return this.http.get("product/get", { product_id: productId });
  }
  public getAllProducts(isPaginate: boolean = false, page: number = 1) {
    return this.http.get("product/get-all", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
  public getAllItems(isPaginate: boolean = false, page: number = 1) {
    return this.http.get("product/get-keys", {
      is_paginate: `${+isPaginate}`,
      page,
    });
  }
  public getItemBasedOnProduct(productId: number) {
    return this.http.get("product/get-items", { product_id: productId });
  }
}
